import React from 'react';
import { Link } from 'gatsby';
import { Header, Image, Icon, Label } from 'semantic-ui-react';

import '../style/navigation.css';
import CartContext from '../components/CartContext';
import { toHyphenCase } from '../utils/comm';

import SignatureStatic from '../data/graphics/signature-static.png';

const menuItems = ['gallery', 'in the wild', 'contact', 'about','happenings'];

const activeStyle = {
  borderBottom: "solid 2px #787878",
};

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false }

    this.onToggleNav = this.onToggleNav.bind(this);
  }

  onToggleNav() {
    const { open } = this.state;

    this.setState({ open: !open })
  }

  render() {
    const { open } = this.state;

    return (
      <div className="navigation-container mobile-nav">
        <Icon name="bars" size="large" className="mobile-nav-toggle" onClick={this.onToggleNav}/>
        <div className="mobile-nav-header">
          <div className="navigation-signature">
            <Link to="/gallery/">
            <Image src={SignatureStatic} size="small"/>
            </Link>
          </div>
          <div className="navigation-title">
            <Header as="h3">ARI HAUBEN</Header>
          </div>
        </div>
        {open && (
          <div className="mobile-nav-items">
            {menuItems.map(item => (
              <div>
                <Link to={`/${toHyphenCase(item)}/`} activeStyle={activeStyle} onClick={this.onToggleNav} key={item}>
                  <Header as="h5">{item.toUpperCase()}</Header>
                </Link>
              </div>
            ))}
            {/*<CartContext.Consumer>*/}
              {/*{*/}
                {/*({ cartItems }) => (*/}
                  {/*<div style={{ marginTop: '1em' }} onClick={this.onToggleNav}>*/}
                    {/*<Link to={`/cart`}>*/}
                      {/*<Icon.Group size="large">*/}
                        {/*<Icon name="cart" color="black" />*/}
                        {/*<Label color="red" circular floating>*/}
                          {/*{cartItems.length}*/}
                        {/*</Label>*/}
                      {/*</Icon.Group>*/}
                    {/*</Link>*/}
                  {/*</div>*/}
                {/*)*/}
              {/*}*/}
            {/*</CartContext.Consumer>*/}
          </div>
        )}
      </div>
    )
  }
}
